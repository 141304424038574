<template>
  <div>
    <button class="pro_add" type="button" @click="handleClickSub"></button>
    {{ num }}
    <button class="pro_redu" type="button" @click="handleClickAdd"></button>
  </div>
</template>

<script>
import { ref, watch } from 'vue-demi';

export default {
  props: ['value', 'max', 'min', 'disabled'],
  setup(props, context) {
    const min = props.min ?? 1;
    const num = ref(props.value ?? 1);
    watch(
      () => props.value,
      (newValue) => {
        num.value = newValue;
      }
    );

    return {
      num,
      handleClickAdd() {
        if (props.disabled) return;

        if (props.max && props.max <= num.value) return;

        num.value += 1;

        context.emit('input', num.value);
      },
      handleClickSub() {
        if (props.disabled) return;
        if (num.value <= min) return;

        num.value -= 1;

        context.emit('input', num.value);
      },
    };
  },
};
</script>

<style scoped>
.pro_add,
.pro_redu {
  width: 24px !important;
  height: 24px !important;
  min-width: 0 !important;
}
</style>
