<template>
  <filter-layout-popup
    :title="$t('Review.ReviewFilterEng')"
    @submit="$emit('input', filters)"
    @reset="handleClickReset"
    @close="$emit('close')"
  >
    <div slot="header-top" class="re_option btwlist disflex">
      <p class="con">
        <KsCheckBox
          v-model="filters.isOnlyPhoto"
          :item="{ label: $t('Review.PhotoReview') }"
        />
      </p>

      <div id="select-wrap" class="review_order">
        <div
          id="select"
          class="select btn_pop_open select40"
          data-popup="filter_review_popup"
        >
          {{ $t('Review.OrderByNew') }}
        </div>
      </div>
      <button type="button" class="sliders-light btn_pop_open" />
    </div>
    <side-tab :options="options" value="option">
      <side-tab-item key="option">
        <div class="option_box pop_option_box">
          <ReviewSelectBox
            v-for="(options, key) in filters.options"
            :key="key"
            :options="filters.options"
            :selectKey="filters.options[key]"
            :items="options"
            :placeholder="$t('Review.OptionSelect')"
            @filters="inputFilterValue"
          />
        </div>
      </side-tab-item>

      <side-tab-item key="gender">
        <li v-for="(gender, idx) in gender" :key="gender.key">
          <KsCheckBox
            v-model="filters.gender[idx]"
            :item="gender"
            name="gender"
          />
        </li>
      </side-tab-item>

      <side-tab-item key="age">
        <li v-for="(age, idx) in age" :key="age.key">
          <KsCheckBox v-model="filters.age[idx]" :item="age" />
        </li>
      </side-tab-item>

      <side-tab-item key="height">
        <li v-for="(height, idx) in heights" :key="height.key">
          <KsCheckBox v-model="filters.heights[idx]" :item="height" />
        </li>
      </side-tab-item>

      <side-tab-item key="weight">
        <li v-for="(weight, idx) in weights" :key="weight.key">
          <KsCheckBox v-model="filters.weights[idx]" :item="weight" />
        </li>
      </side-tab-item>
    </side-tab>
  </filter-layout-popup>
</template>

<script>
import SideTab from '../../../components/ui/SideTab/SideTab.vue';
import SideTabItem from '../../../components/ui/SideTab/SideTabItem.vue';
import {
  addBodyOverflowHidden,
  removeBodyOverflowHidden,
} from '../../../utils/CommonUtils';
import KsCheckBox from '../../../components/Inputs/KsCheckBox/KsCheckBox.vue';
import consts from '../../../consts/FilterItems';
import { reactive, toRefs } from 'vue-demi';
import FilterLayoutPopup from '../../../components/popups/FilterLayoutPopup.vue';
import ProductAPI from '@/apis/ProductAPI';
import ReviewSelectBox from '../../../components/Inputs/SelectBox/ReviewSelectBox';

export default {
  props: ['value', 'filterOptions'],
  components: {
    ReviewSelectBox,
    SideTab,
    SideTabItem,
    KsCheckBox,
    FilterLayoutPopup,
  },
  setup(props) {
    const cit_id = { id: props.filterOptions };
    addBodyOverflowHidden();

    const state = reactive({
      filters: {
        options: [],
        gender: [],
        age: [],
        heights: [],
        weights: [],
        getOptionValue: [],
        isOnlyPhoto: false,
      },
    });

    const filterOptions = async () => {
      console.log('-@@@@@@');
      console.log(cit_id);
      console.log('-@@@@@@');
      await ProductAPI.get_product_review_options(cit_id).then((optionList) => {
        console.log('---------');
        console.log(optionList.data.options);
        console.log('---------');
        state.filters.options = [...optionList.data.options];
        state.filters.getOptionValue = [];
        optionList.data.options.map((value) => {
          state.filters.getOptionValue.push({ id: value.cio_id });
        });
      });
      await Object.keys(props.value).forEach((key) => {
        const value = props.value[key];
        if (typeof value === 'object') {
          state.filters[key] = [...value];
        } else {
          state.filters[key] = value;
        }
      });
    };
    filterOptions();

    console.log(state.filters.options);
    console.log(state.filters.getOptionValue);
    return {
      ...consts,
      ...toRefs(state),

      inputFilterValue(getValue, item, key) {
        state.filters.getOptionValue.filter((value, index) => {
          if (value.id === key)
            state.filters.getOptionValue[index].value = item;
        });
        console.log('이걸로 전송해야댐 : ', state.filters.getOptionValue);
      },
      handleClickReset() {
        state.filters = {
          options: [],
          gender: [],
          age: [],
          heights: [],
          weights: [],
          getOptionValue: [],
          isOnlyPhoto: false,
        };
        filterOptions();
      },
    };
  },
  destroyed() {
    removeBodyOverflowHidden();
  },
};
</script>

<style scoped>
.layout_popup.filter_layout_pop ::v-deep .layout_contain {
  height: 100%;
}
</style>
