import { defineStore } from 'pinia';
import ProductAPI from '@/apis/ProductAPI';

export default defineStore('useFilterInfo', () => {
  const info = {};

  const getFilterInfo = async type => {
    if (!info[type]) await updateFilterInfo(type);

    return info[type];
  };

  const updateFilterInfo = async type => {
    const { data } = await ProductAPI[`get_${type}`]();

    info[type] = data;
  };

  return { getFilterInfo };
});
