<template>
  <div class="down-up-popup look_layput_pop fix_foot_btn">
    <default-full-dialog
      v-bind="$attrs"
      @close="$emit('close')"
      @confirm="$emit('confirm')"
    >
      <template slot="header">
        <slot name="header"></slot>
      </template>

      <template slot="rightBottom">
        <slot name="rightBottom"> </slot>
      </template>

      <template slot="body">
        <slot name="body"> </slot>
      </template>

      <template slot="footer">
        <slot name="footer"></slot>
      </template>
    </default-full-dialog>
  </div>
</template>

<script>
import DefaultFullDialog from '../layouts/DefaultFullDialog.vue';

export default {
  components: { DefaultFullDialog },
};
</script>

<style>
.down-up-popup .layout_contain {
  animation: fadeInUp 0.5s;
}
</style>
