<template>
  <div class="pop_body">
    <div class="chk_btn_list">
      <button
        v-for="option in options"
        :key="option.key"
        type="button"
        :class="active === option.key ? 'on' : ''"
        @click="handleClick(option.key)"
        v-text="option.label"
      />
    </div>

    <div class="chk_list_box">
      <slot />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue-demi';
import ArrayUtils from '../../../utils/ArrayUtils';

const setNodes = (vnodes, active) => {
  vnodes.forEach(node => {
    const key = node.key;
    const elm = node.elm;
    const classnames = elm.className.split(' ');

    // ArrayUtils.delete(classnames, 'on')

    if (active === key) {
      if (classnames.indexOf('on') === -1) {
        classnames.push('on');
      }
    } else {
      ArrayUtils.delete(classnames, 'on');
    }

    elm.className = classnames.join(' ');
  });
};

export default {
  props: ['options', 'value'],
  setup(props, context) {
    const active = ref(props.value);

    return {
      active,
      handleClick(key) {
        active.value = key;

        setNodes(context.slots.default(), key);
      },
    };
  },
  mounted() {
    setNodes(this.$slots.default, this.value);
  },
};
</script>

<style></style>
