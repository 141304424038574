<template>
  <div ref="selectBoxRef" :class="`select_box ${menuClass}`">
    <div class="box">
      <div class="select" @click="handelMenuClick">
        {{ option.option_name }}
      </div>
      <ul class="list">
        <li
          v-for="(item, idx) in option.option_item"
          :key="item"
          :class="getClassName(idx)"
          @click="handleClickMenu($event, item, option.key)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue-demi';

export default {
  props: [
    'items',
    'name',
    'value',
    'defaultValue',
    'placeholder',
    'noItemText',
    'selectKey',
    'options',
  ],
  setup(props, context) {
    const state = reactive({
      option: {
        option_name: null,
        option_item: [],
        key: null,
      },
      menuClass: String,
      selectItem: false,
      selectValue: [],
    });
    let option_item = ref([]);
    state.option.key = props.selectKey.cio_id;
    option_item.value.push(...props.selectKey.option_val.split(','));
    state.option.option_name = props.selectKey.option_name;
    state.option.option_item = option_item;
    return {
      ...toRefs(state),
      handleClickMenu(e, item, key, idx) {
        context.emit('filters', '', item, key, idx);
        state.option.option_name = item;
        state.menuClass = '';
      },
      handelMenuClick() {
        state.menuClass === 'on'
          ? (state.menuClass = '')
          : (state.menuClass = 'on');
      },
      getClassName() {
        const classname = [];
        classname.push('selected');
        return classname.join(' ');
      },
    };
  },
};
</script>

<style>
.select_box {
  cursor: pointer;
}
.select_box.on .list {
  display: block;
}
.select_box.type06 .list {
  border: none;
}
.select_box .box .list > li.disabled {
  color: #ccc;
}
</style>
