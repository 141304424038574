<template>
  <div class="fit_selec radio-group">
    <h6>{{ title }}</h6>
    <ul>
      <li v-for="(item, idx) in items" :key="item.key">
        <label>
          <input type="radio" :name="name" :checked="selected === idx" />
          <button type="button" @click="handleClick(idx)">
            {{ item.label }}
          </button>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
export default {
  props: {
    title: {},
    name: { default: 'radioGroup' },
    items: { default: [] },
    value: {},
  },
  setup(props, context) {
    const idx = props.items.findIndex(item => item.key === props.value);

    const state = reactive({
      selected: idx === -1 ? 0 : idx,
    });

    if (idx === -1) {
      context.emit('input', props.items[0].key);
    }

    return {
      ...toRefs(state),
      handleClick(idx) {
        state.selected = idx;

        context.emit('input', props.items[idx].key);
      },
    };
  },
};
</script>

<style>
.radio-group input {
  display: none;
}
.radio-group input:checked ~ button {
  color: #fff;
  font-size: 12px;
  background-color: #202020;
  border: none;
}
</style>
