<template>
  <div ref="selectBoxRef" class="select_box" @click="handleClick">
    <div class="box">
      <div class="select">{{ selected.label }}</div>
      <ul class="list">
        <li
          v-for="item in items"
          :key="item.key"
          :class="getClassName(item)"
          @click="handleClickMenu($event, item)"
        >
          {{ item.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue-demi';
export default {
  props: [
    'items',
    'name',
    'value',
    'defaultValue',
    'placeholder',
    'noItemText',
  ],
  setup(props, context) {
    const selectBoxRef = ref(null);
    const selected = ref(null);
    const defaultValue = props.defaultValue ? props.defaultValue : props.value;

    const emitInputValue = () => {
      context.emit('input', selected.value.key, selected.value);
      context.emit('change', selected.value.key, selected.value);
    };

    const selectFirstItem = () => {
      selected.value = { ...props.items[0] };

      if (selected.value.key !== props.value) {
        emitInputValue();
      }
    };

    const setSelectValue = () => {
      props.items.forEach(item => {
        if (item.key === defaultValue) {
          selected.value = item;
        }
      });
    };

    const setPlaceholder = () => {
      if (!props.placeholder) return;

      selected.value = { label: props.placeholder };
    };

    const setNoItems = () => {
      selected.value = { label: props.noItemText ?? '' };
    };

    const setInitValue = () => {
      const isNoItems = !props.items || props.items.length === 0;

      if (isNoItems) setNoItems();

      // defaultValue값에 맞는 아이템 매치
      if (!selected.value) setSelectValue();

      // setSelectValue에서 매칭이 되지 않은 경우
      if (!selected.value) setPlaceholder();

      // placeholder가 설정되지 않은 경우
      if (!selected.value) selectFirstItem();
    };

    setInitValue();

    watch(() => props.items, setInitValue);

    return {
      props,
      selected,
      selectBoxRef,
      handleClickMenu(e, item) {
        if (item.disabled) {
          e.cancelBubble = true;
          return;
        }

        selected.value = item;

        emitInputValue();
      },
      handleClick(e) {
        e.cancelBubble = true;

        const node = selectBoxRef.value;

        const classes = node.className.split(' ');

        const idx = classes.indexOf('on');
        idx === -1 ? classes.push('on') : classes.splice(idx, 1);

        node.className = classes.join(' ');
      },

      setValue(value) {
        const item = props.items.find(item => item.key == value);

        if (item) selected.value = item;

        emitInputValue();
      },

      getClassName(item) {
        const classname = [];

        if (item.key === selected.value.key) {
          classname.push('selected');
        }
        if (item.disabled) {
          classname.push('disabled');
        }

        return classname.join(' ');
      },
    };
  },
};
</script>

<style>
.select_box {
  cursor: pointer;
}
.select_box.on .list {
  display: block;
}
.select_box.type06 .list {
  border: none;
}
.select_box .box .list > li.disabled {
  color: #ccc;
}
</style>
