<template>
  <div class="wrap">
    <close-title-basket :title="product ? product.den_name : ''" />

    <div class="layout_body box_p60 re_layout">
      <div v-if="product" class="review_con disflex">
        <product-image :item="product" class="review_pro" />
        <div class="detail_pro">
          <h6 class="fontEn font2">{{ product.cit_name }}</h6>
          <p class="font2">
            {{ product.cit_summary === '' ? '' : product.cit_summary }}
          </p>
        </div>
      </div>

      <div class="rating_type inner ptb14">
        <button class="btn_lineblack" type="button" @click="handleReviewCheck">
          {{ $t('Review.WriteReview') }}
        </button>
        <!--        <router-link   :to="`/product/review-write/${product.cit_id}/cde_id`"  class="btn_lineblack"  style="width: 100%; text-align: center"></router-link>-->
        <div class="select_container">
          <!-- 셀렉트 박스 하나 -->
          <div class="select_box type01">
            <div class="box">
              <div class="select disflex" @click="handleReviewGrapOpen">
                <span>{{ $t('Review.Grade') }}</span>
                <review-score
                  v-if="product"
                  :value="product.cit_review_average"
                  style="width: 80px"
                />
                <strong>{{
                  product ? product.cit_review_average : '0'
                }}</strong>
              </div>
              <div
                :class="`${isDrop ? 'drop_down' : 'drop_up'}`"
                class="review_grap_wrap"
              >
                <ul>
                  <li class="grap_list">
                    <span class="grap_title">{{
                      $t('Review.GradeNumber', { Num: 5 })
                    }}</span>
                    <div class="grap">
                      <span class="tumb five"></span>
                    </div>
                  </li>
                  <li class="grap_list">
                    <span class="grap_title">{{
                      $t('Review.GradeNumber', { Num: 4 })
                    }}</span>
                    <div class="grap">
                      <span class="tumb four"></span>
                    </div>
                  </li>
                  <li class="grap_list">
                    <span class="grap_title">{{
                      $t('Review.GradeNumber', { Num: 3 })
                    }}</span>
                    <div class="grap">
                      <span class="tumb three"></span>
                    </div>
                  </li>
                  <li class="grap_list">
                    <span class="grap_title">{{
                      $t('Review.GradeNumber', { Num: 2 })
                    }}</span>
                    <div class="grap">
                      <span class="tumb two"></span>
                    </div>
                  </li>
                  <li class="grap_list">
                    <span class="grap_title">{{
                      $t('Review.GradeNumber', { Num: 1 })
                    }}</span>
                    <div class="grap">
                      <span class="tumb one"></span>
                    </div>
                  </li>
                </ul>
              </div>
              <ul class="list">
                <li class="selected disflex">
                  {{ $t('Review.GradeNumber', { Num: 5 }) }}<span></span>
                </li>
                <li>{{ $t('Review.GradeNumber', { Num: 4 }) }}<span></span></li>
                <li>{{ $t('Review.GradeNumber', { Num: 3 }) }}<span></span></li>
                <li>{{ $t('Review.GradeNumber', { Num: 2 }) }}<span></span></li>
                <li>{{ $t('Review.GradeNumber', { Num: 1 }) }}<span></span></li>
              </ul>
            </div>
          </div>
          <div>
            <ul class="disflex review_type">
              <li class="g_left">{{ $t('Review.Fit') }}</li>
              <li class="w_right">
                {{ reviewScore ? reviewScore.grip.fit : '-' }}
              </li>

              <li class="g_left">{{ $t('Review.Elasticity') }}</li>
              <li class="w_right">
                {{ reviewScore ? reviewScore.grip.elasticity : '-' }}
              </li>

              <li class="g_left">{{ $t('Review.Thickness') }}</li>
              <li class="w_right">
                {{ reviewScore ? reviewScore.grip.thickness : '-' }}
              </li>

              <li class="g_left">{{ $t('Review.SeeThrough') }}</li>
              <li class="w_right">
                {{ reviewScore ? reviewScore.grip.opacity : '-' }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="review_wrap">
        <div class="re_option btwlist disflex">
          <p class="con">
            <KsCheckBox
              v-model="filters.isOnlyPhoto"
              :item="{ label: $t('Review.PhotoReview') }"
              @input="handleChangePhoto"
            />
          </p>

          <div id="select-wrap" class="review_order">
            <div
              id="select"
              class="select btn_pop_open"
              @click="isShowSortPopup = true"
            >
              {{ sort.label }}
            </div>
          </div>

          <a class="sliders-light btn_pop_open" @click="isShowFilter = true" />
        </div>
        <h3 class="tit_b">
          {{ $t('Mypage.Review') }}
          <span>{{
            product ? product.cit_review_count.numberFormat() : ''
          }}</span>
        </h3>
        <review-item
          v-for="review in reviews"
          :key="review.cre_id"
          :item="review"
        />
        <infinite-loading
          v-if="hasMore"
          :identifier="infiniteId"
          @infinite="loadReviewData"
        />
      </div>
    </div>

    <review-filter-popup
      v-if="isShowFilter"
      v-model="filters"
      :filterOptions="filterOptions"
      @close="handleCloseFilter"
      @input="handleConfirmFilter"
    />
    <sort-popup
      v-if="isShowSortPopup"
      :items="sortItems"
      :title="$t('Review.ReviewFilter')"
      @close="isShowSortPopup = false"
      @select="handleSelectSort"
    />

    <review-write-view
      v-if="isShowReviewWrite"
      :cit_id="product.cit_id"
      :orderId="dataSet.orderId"
      :codId="dataSet.codId"
      @close="isShowReviewWrite = false"
      @update="handleUpdateReview"
    />
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue-demi';
import ProductAPI from '../../apis/ProductAPI';
import CloseTitleBasket from '../../components/layouts/components/headers/CloseTitleBasket.vue';
import ReviewScore from '../../components/ui/ReviewScore/ReviewScore.vue';
import ReviewItem from './components/ReviewItem.vue';
import ReviewFilterPopup from './components/ReviewFilterPopup.vue';
import SortPopup from '../../components/popups/SortPopup.vue';
import KsCheckBox from '../../components/Inputs/KsCheckBox/KsCheckBox.vue';
import InfiniteLoading from 'vue-infinite-loading';
import ProductImage from '../../components/ui/ProductImage/ProductImage.vue';
import useAlert from '@Composables/dialogs/useAlert';

import ReviewWriteView from '@Views/products/ReviewWriteView';

export default {
  components: {
    ReviewWriteView,
    CloseTitleBasket,
    ReviewScore,
    ReviewItem,
    ReviewFilterPopup,
    SortPopup,
    KsCheckBox,
    InfiniteLoading,
    ProductImage,
  },
  props: ['product_id'],
  setup(props, context) {
    const sortItems = [
      {
        key: 'ins_dtm',
        label: context.root.$t('Review.OrderByNew'),
      },
      {
        key: 'crc_count',
        label: context.root.$t('Review.OrderByComment'),
      },
      {
        key: 'score_desc',
        label: context.root.$t('Review.OrderByHigh'),
      },
      {
        key: 'score_asc',
        label: context.root.$t('Review.OrderByLow'),
      },
    ];
    const id = props.product_id
      ? props.product_id
      : context.root.$route.params.id;
    console.log('&&&&&&&&&&');
    console.log(id);
    console.log('&&&&&&&&&&');
    const { showAlert } = useAlert();
    const $ = window.$;
    const state = reactive({
      product: null,
      reviews: [],
      filters: {},
      isShowFilter: false,
      isShowSortPopup: false,
      isShowPhotoDetail: false,
      sort: sortItems[0],
      hasMore: true,
      infiniteId: Date.now(),
      page: 0,
      urls: [],
      reviewItem: null,
      imageActiveIndex: 0,
      filterOptions: null,
      isDrop: false,
      score: {
        five: 0,
        four: 0,
        three: 0,
        two: 0,
        one: 0,
        total: 0,
      },
      isShowReviewWrite: false,
      dataSet: {
        orderId: null,
        codId: null,
      },
      reviewScore: null,
      reviewAverage: null,
    });

    const loadProduct = async () => {
      const { data } = await ProductAPI.getProduct(id);
      state.product = data.result_data;
      state.reviewItem = data.review;
      console.log(state.product);
      state.score.total = data.review.count;
      state.filterOptions = data.result_data.cit_id;
    };

    const loadReviewData = async (attr) => {
      const params = {
        id,
        page: state.page,
        limit: 10,
        ...createFilterValues(),
        sort: state.sort.key,
      };
      console.log('state.page : ', state.page);
      console.log('state.sort', state.sort);
      console.log('state.sort.key', state.sort.key);
      console.log(attr);
      const { data } = await ProductAPI.getProductReview(params);
      state.reviews = [...state.reviews, ...data.result_data];
      state.reviewScore = data.score;
      state.reviewAverage = data.score.avg[0].cre_score_avg;

      console.log(data.result_data, '<-sssssss');
      console.log(state.reviews, '<-sssssss');
      console.log(state.reviewAverage, '<-sssssss');
      if (data.result_data.length !== params.limit) {
        attr?.complete();
        state.hasMore = false;
      } else {
        state.page++;
        attr?.loaded();
      }
    };
    const loadCreateReviewData = async () => {
      const params = {
        id,
        page: state.page,
        limit: 10,
        ...createFilterValues(),
        sort: state.sort.key,
      };

      console.log('state.page : ', state.page);
      console.log('state.sort', state.sort);
      console.log('state.sort.key', state.sort.key);
      params.page = 0;
      state.reviews = [];
      const { data } = await ProductAPI.getProductReview(params);
      state.reviews = data.result_data;
      console.log(state.reviews, '<-sssssss');
    };
    watch(
      () => state.reviews,
      (newValue) => {
        console.log(newValue);
      }
    );

    const createFilterValues = () => {
      const filters = {};

      Object.keys(state.filters).forEach((key) => {
        const value = state.filters[key];

        if (typeof value === 'object') {
          filters[key] = value
            .map((checked, idx) => (checked ? idx + 1 : null))
            .filter((val) => val !== null);
        } else {
          filters[key] = value;
        }
      });

      return filters;
    };

    loadProduct();

    const clearSearchParams = () => {
      state.hasMore = true;
      state.page = 0;
      state.reviews = [];
    };

    return {
      sortItems,
      props,
      ...toRefs(state),
      loadReviewData,
      loadCreateReviewData,
      handleCloseFilter() {
        state.isShowFilter = false;
      },
      handleUpdateReview() {
        loadCreateReviewData();
      },
      handleConfirmFilter() {
        state.isShowFilter = false;
        clearSearchParams();
      },
      handleSelectSort(item) {
        state.isShowSortPopup = false;
        state.sort = item;
        clearSearchParams();
      },
      handleReviewGrapOpen() {
        if (state.isDrop) {
          state.isDrop = false;
          $('.select.disflex').removeClass('unselect');
          $('.tumb').css({ width: 0 + '%', transition: 'none' });
          state.score.five = 0;
          state.score.four = 0;
          state.score.three = 0;
          state.score.two = 0;
          state.score.one = 0;
        } else {
          state.isDrop = true;
          $('.select.disflex').addClass('unselect');
          this.grapCaculate();
        }
      },
      grapCaculate() {
        console.log(state.score.total, 'state.score.total');

        state.reviewScore.list.filter((reviewItem) => {
          switch (reviewItem.cre_score) {
            case '5':
              state.score.five = reviewItem.cnt;
              break;
            case '4':
              state.score.four = reviewItem.cnt;
              break;
            case '3':
              state.score.three = reviewItem.cnt;
              break;
            case '2':
              state.score.two = reviewItem.cnt;
              break;
            case '1':
              state.score.one = reviewItem.cnt;
              break;
          }
        });
        console.log(state.score);
        $('.tumb.five').css({
          transition: 'all 0.6s',
          width: Math.floor((state.score.five / state.score.total) * 100) + '%',
        });
        $('.tumb.four').css({
          transition: 'all 0.6s',
          width: Math.floor((state.score.four / state.score.total) * 100) + '%',
        });
        $('.tumb.three').css({
          transition: 'all 0.6s',
          width:
            Math.floor((state.score.three / state.score.total) * 100) + '%',
        });
        $('.tumb.two').css({
          transition: 'all 0.6s',
          width: Math.floor((state.score.two / state.score.total) * 100) + '%',
        });
        $('.tumb.one').css({
          transition: 'all 0.6s',
          width: Math.floor((state.score.one / state.score.total) * 100) + '%',
        });
      },
      handleChangePhoto() {
        clearSearchParams();
      },
      handleUpdateReivew() {
        if (props.product_id) {
          context.emit('close');
        }
      },
      async handleReviewCheck(e) {
        e.preventDefault();
        const params = {
          cit_id: state.product.cit_id,
          mem_id: localStorage.getItem('mem_id'),
        };

        localStorage.removeItem('order_id');
        localStorage.removeItem('cod_id');

        await ProductAPI.get_review_write_chk(params)

          .then((result) => {
            state.dataSet.orderId = result.data.order_id;
            state.dataSet.codId = result.data.cod_id;
            if (result.data.COUNT === '0') {
              showAlert(context.root.$t('Review.NoneAuthority'));
            } else {
              if (!props.product_id) {
                localStorage.setItem('order_id', result.data.order_id);
                localStorage.setItem('cod_id', result.data.cod_id);
                state.isShowReviewWrite = true;
              } else if (props.product_id) {
                state.isShowReviewWrite = true;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    };
  },
};
</script>

<style>
.select.disflex.unselect {
  background: url('../../assets/images/design/arrow-up-icon.svg') no-repeat
    center right 14px;
}
.review_grap_wrap ul {
  width: 100%;
}
.review_grap_wrap ul li {
  width: 100%;
}
.review_grap_wrap {
  overflow: hidden;
  width: 100%;
}
.review_grap_wrap.drop_down {
  background: transparent;
  height: 160px;
  transition: all 0.6s;
}
.review_grap_wrap.drop_up {
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
}
.review_grap_wrap .grap_list {
  height: 30px;
  position: relative;
}
.review_grap_wrap .grap_list:after {
  clear: both;
  content: '';
  display: block;
}
.review_grap_wrap .grap_list .grap_title,
.review_grap_wrap .grap_list .grap {
  float: left;
  height: 100%;
  position: relative;
  width: calc(100% - 50px);
}
.review_grap_wrap .grap_list .grap_title {
  background: transparent;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  width: 30px;
}
.review_grap_wrap .grap_list .grap_title:after {
  display: none;
}
.review_grap_wrap .grap_list .grap .tumb:after {
  display: none;
}
.review_grap_wrap .grap_list .grap .tumb {
  background: #cccc;
  height: 3px;
  left: 0;
  margin-left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 1s;
  width: 0;
}
</style>
