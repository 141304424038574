import useFilterInfo from '../composables/filters/useFilterInfo';
import { i18n } from '@/plugins/i18n/i18n';
export const options = [
  {
    key: 'option',
    label: i18n.t('Option.option'),
  },
  {
    key: 'gender',
    label: i18n.t('Option.gender'),
  },
  {
    key: 'age',
    label: i18n.t('Option.age'),
  },
  {
    key: 'height',
    label: i18n.t('Option.height'),
  },
  {
    key: 'weight',
    label: i18n.t('Option.weight'),
  },
];

export const itemsTabOptions = [
  {
    key: 'category',
    label: i18n.t('Option.category'),
  },
  {
    key: 'concept',
    label: i18n.t('Option.concept'),
  },
  {
    key: 'gender',
    label: i18n.t('Option.gender'),
  },
  {
    key: 'age',
    label: i18n.t('Option.age'),
  },
  {
    key: 'price',
    label: i18n.t('Option.price'),
  },
  {
    key: 'color',
    label: i18n.t('Option.color'),
  },
  {
    key: 'style',
    label: i18n.t('Option.style'),
  },
];

export const brandDetailTabOptions = [
  {
    key: 'category',
    label: i18n.t('Option.category'),
  },
  {
    key: 'age',
    label: i18n.t('Option.age'),
  },
  {
    key: 'price',
    label: i18n.t('Option.price'),
  },
];

export const brandTabOptions = [
  {
    key: 'concept',
    label: i18n.t('Option.price'),
  },
  {
    key: 'gender',
    label: i18n.t('Option.gender'),
  },
  {
    key: 'age',
    label: i18n.t('Option.age'),
  },
  {
    key: 'style',
    label: i18n.t('Option.style'),
  },
];

export const sizes = [
  {
    key: '1',
    label: 'XS',
  },
  {
    key: '2',
    label: 'S',
  },
  {
    key: '3',
    label: 'M',
  },
  {
    key: '4',
    label: 'L',
  },
  {
    key: '5',
    label: 'XL',
  },
  {
    key: '6',
    label: '44',
  },
  {
    key: '7',
    label: '55',
  },
  {
    key: '8',
    label: '66',
  },
  {
    key: '9',
    label: '77',
  },
  {
    key: '10',
    label: '88',
  },
];
export const items = [
  {
    key: '1',
    label: i18n.t('Option.red'),
  },
  {
    key: '2',
    label: i18n.t('Option.green'),
  },
  {
    key: '3',
    label: i18n.t('Option.blue'),
  },
  {
    key: '4',
    label: i18n.t('Option.pink'),
  },
];

export const gender = [
  {
    key: 'm',
    label: i18n.t('Option.man'),
  },
  {
    key: 'w',
    label: i18n.t('Option.woman'),
  },
];

export const age = [
  {
    key: 10,
    label: i18n.t('Option.ages', { number: 10 }),
  },
  {
    key: 20,
    label: i18n.t('Option.ages', { number: 20 }),
  },
  {
    key: 30,
    label: i18n.t('Option.ages', { number: 30 }),
  },
  {
    key: 40,
    label: i18n.t('Option.ages', { number: 40 }),
  },
  {
    key: 50,
    label: i18n.t('Option.ages', { number: 50 }),
  },
];
export const ages2 = [
  {
    key: '10대',
    label: i18n.t('Option.ages', { number: 10 }),
  },
  {
    key: '20대 초반',
    label: i18n.t('Option.EarlyAges', { num1: 20 }),
  },
  {
    key: '30대 초반',
    label: i18n.t('Option.EarlyAges', { num1: 30 }),
  },
  {
    key: '30대 중반',
    llabel: i18n.t('Option.MidAges', { num2: 30 }),
  },
  {
    key: '30대 후반',
    label: i18n.t('Option.LaterAges', { num3: 30 }),
  },
];

export const heights = [
  {
    key: 1,
    label: i18n.t('Option.Under', { body1: '150cm' }),
  },
  {
    key: 2,
    label: '151cm ~ 160cm',
  },

  {
    key: 3,
    label: '161cm ~ 170cm',
  },

  {
    key: 4,
    label: '171cm ~ 180cm',
  },

  {
    key: 5,
    label: '181cm ~ 190cm',
  },

  {
    key: 6,
    label: i18n.t('Option.MoreThan', { body2: '191cm' }),
  },
];

export const weights = [
  {
    key: 1,
    label: i18n.t('Option.Under', { body1: '50kg' }),
  },
  {
    key: 2,
    label: '51kg ~ 60kg',
  },
  {
    key: 3,
    label: '61kg ~ 70kg',
  },
  {
    key: 4,
    label: '71kg ~ 80kg',
  },
  {
    key: 5,
    label: '81kg ~ 90kg',
  },
  {
    key: 6,
    label: '91kg ~ 100kg',
  },
  {
    key: 7,
    label: i18n.t('Option.MoreThan', { body2: '100kg' }),
  },
];

const { getFilterInfo } = useFilterInfo();

export const getProductCategories = async () => {
  const data = await getFilterInfo('category');
  const categories = [];
  // API가 펼쳐져서 전달됨
  // 카테고리 1레벨 넣기
  for (const category1 of data.result_data) {
    if (Number(category1.depth) === 1) {
      categories.push({
        key: category1.cca_id,
        label: category1.cca_value,
        cca_value: category1.cca_value,
        cca_id: category1.cca_id,
        cca_parent: category1.cca_parent,
        depth: category1.depth,
        folding: category1.depth === Number(1) ? true : false,
        children: [],
      });
    }
  }
  // 카테고리2레벨을 위에서 넣은 1레벨 배열에서 parent 관계로 찾아서 넣음
  for (const category2 of data.result_data) {
    if (Number(category2.depth) === 2) {
      for (const category1 of categories) {
        if (category2.cca_parent === category1.cca_id) {
          category1.children.push({
            key: category2.cca_id,
            label: category2.cca_value,
            cca_value: category2.cca_value,
            cca_id: category2.cca_id,
            cca_parent: category2.cca_parent,
            depth: category2.depth,
            folding: false,
            children: [],
          });
        }
      }
    }
  }
  // 카테고리3레벨을 위에서 넣은 1레벨 배열 안의 2레벨 배열에서 찾음
  for (const category3 of data.result_data) {
    if (Number(category3.depth) === 3) {
      for (const category1 of categories) {
        for (const category2 of category1.children) {
          if (category2.cca_id === category3.cca_parent) {
            category2.children.push({
              key: category3.cca_id,
              label: category3.cca_value,
              cca_value: category3.cca_value,
              cca_id: category3.cca_id,
              cca_parent: category3.cca_parent,
              depth: category3.depth,
              folding: false,
              children: [],
            });
          }
        }
      }
    }
  }
  console.log('categories');
  console.log(categories);
  return categories;
};

export const getConcepts = async () => {
  const data = await getFilterInfo('concept');

  return data.result_data.map(concept => ({
    key: concept.concept_id,
    label: concept.concept_value,
  }));
};

export const getColors = async () => {
  const data = await getFilterInfo('color');

  return data.result_data.map(color => ({
    key: color.color_id,
    label: color.color_title,
    code: color.color_code,
  }));
};

export const getStyles = async () => {
  const data = await getFilterInfo('style');

  return data.result_data.map(style => ({
    key: style.style_id,
    label: style.style_value,
  }));
};

const getItemFilterOptions = async () => {
  const items = {
    age: age,
    gender: gender,
    size: sizes,
    color: await getColors(),
    concept: await getConcepts(),
    category: await getProductCategories(),
    style: await getStyles(),
  };

  return items;
};

export default {
  weights,
  heights,
  age,
  gender,
  items,
  sizes,
  options,
  itemsTabOptions,
  brandTabOptions,
  brandDetailTabOptions,
  getColors,
  getStyles,
  getConcepts,
  getProductCategories,
  getItemFilterOptions,
};
