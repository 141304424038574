<template>
  <div class="review_box">
    <div class="pro_desc_box type3 inner">
      <image-bg-src :src="item.mem_img_url" class="profile_img" />
      <div class="profile_user">
        <h3 class="name">{{ item.mem_userid }}</h3>

        <div class="desc date">
          {{
            item.ins_dtm === item.upd_dtm
              ? item.ins_dtm.substr(0, 10)
              : item.upd_dtm.substr(0, 10) + '(수정)'
          }}
        </div>

        <p class="desc">
          <template v-if="item.mem_gender">
            {{
              item.mem_gender === 'woman'
                ? $t('Review.Women')
                : $t('Review.Women')
            }}, {{ item.mem_height }}cm, {{ item.mem_weight }} kg
          </template>
        </p>
      </div>
    </div>
    <div class="sinner review_swiper">
      <swiper class="prod_swiper nomal_swiper" :options="options">
        <swiper-slide v-for="(url, idx) in urls" :key="idx">
          <image-bg-src
            :src="url"
            class="prod_img"
            @click="handleClickImage(idx)"
          />
        </swiper-slide>
      </swiper>
    </div>
    <div class="inner personal_review">
      <ul class="disflex review_type mb10">
        <li>{{ $t('Review.Option') }}</li>
        <li>{{ item.cde_title }}</li>
        <li>{{ $t('Review.Fit') }}</li>
        <li>{{ item.fit }}</li>
        <li>{{ $t('Review.Elasticity') }}</li>
        <li>{{ item.elasticity }}</li>
        <li>{{ $t('Review.Thickness') }}</li>
        <li>{{ item.thickness }}</li>
        <li>{{ $t('Review.SeeThrough') }}</li>
        <li>{{ item.opacity }}</li>
      </ul>
      <review-score :value="item.cre_score" />
      <router-link :to="`/product/review/${item.cit_id}/${item.cre_id}`">
        <div class="persnal_text list">
          <h3>{{ item.cre_title }}</h3>
          <p>{{ item.cre_content }}</p>
        </div>
        <div v-if="isShowComment" class="comment pb10">
          <span class="disflex"
            >{{ $t('Review.Comment') }} {{ item.crc_count }}</span
          >
        </div>
      </router-link>
      <slot></slot>
    </div>
    <photo-detail
      v-if="isShowPhotoDetail"
      :urls="urls"
      :activeIndex="imageActiveIndex"
      style="display: block"
      @close="isShowPhotoDetail = false"
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ImageBgSrc from '../../../components/ui/ImageBlock/ImageBgSrc.vue';
import ReviewScore from '../../../components/ui/ReviewScore/ReviewScore.vue';
import PhotoDetail from '../../../components/ui/PhotoDetail/PhotoDetail.vue';
import { reactive, toRefs, watch } from 'vue-demi';

export default {
  components: { Swiper, SwiperSlide, ImageBgSrc, ReviewScore, PhotoDetail },
  props: {
    item: {},
    isShowComment: { default: true },
  },
  setup(props, context) {
    const state = reactive({
      imageActiveIndex: 0,
      isShowPhotoDetail: false,
      propsItem: props.item,
      urls: [],
    });

    watch(
      () => props.item,
      (newValue) => {
        console.log('$$$$$$$$$$$$!!!!!!$');
        state.propsItem = newValue;
        state.urls = [];
        const image = () => {
          for (let i = 1; i < 11; i++) {
            const url = state.propsItem[`img_file${i}`];

            if (url) state.urls.push(url);
          }
        };
        image();
      }
    );

    state.urls = [];
    const image = () => {
      for (let i = 1; i < 11; i++) {
        const url = state.propsItem[`img_file${i}`];

        if (url) state.urls.push(url);
      }
    };
    image();
    return {
      image,
      ...toRefs(state),
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
      },
      getAge(age) {
        if (age >= 10 && age <= 19)
          return context.root.$t('Review.Age , {Age : 10 }');
        if (age >= 20 && age <= 29)
          return context.root.$t('Review.Age , {Age : 20 }');
        if (age >= 30 && age <= 39)
          return context.root.$t('Review.Age , {Age : 30 }');
        if (age >= 40 && age <= 49)
          return context.root.$t('Review.Age , {Age : 40 }');
        if (age >= 50 && age <= 59)
          return context.root.$t('Review.Age , {Age : 50 }');
      },

      handleClickImage(idx) {
        state.imageActiveIndex = idx;
        state.isShowPhotoDetail = true;
      },
    };
  },
};
</script>

<style></style>
