<template>
  <ul class="disflex">
    <li
      v-for="(className, idx) in classnames"
      :key="idx"
      :class="className"
      @click="handleClickStart(idx + 1)"
    />
  </ul>
</template>

<script>
import { ref } from 'vue-demi';
export default {
  props: {
    edit: {
      default: false,
    },
    value: {
      default: 0,
    },
  },
  setup({ value, edit }, context) {
    const classnames = ref([]);

    const createClassnames = value => {
      const names = [];

      for (let i = 1; i < 6; i++) {
        let classname = 'rating';

        if (value >= i) classname += ' active';

        names.push(classname);
      }

      classnames.value = names;
    };

    createClassnames(value);

    return {
      classnames,
      handleClickStart(value) {
        if (edit) {
          createClassnames(value);
          context.emit('input', value);
        }
      },
    };
  },
};
</script>

<style></style>
