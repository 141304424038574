<template>
  <div
    class="layout_popup filter_layout_pop fix_foot_btn open"
    :style="`${isNewBrandPop ? 'height:100%;' : ''}`"
  >
    <div class="layout_contain open">
      <div
        class="layout_box"
        :style="`${isNewBrandPop ? 'height:100%;overflow:auto;' : ''}`"
      >
        <slot name="header-top" />
        <div class="pop_header">
          <h3 class="title_modal">{{ title }}</h3>
          <button
            type="button"
            class="close btn_dim_close"
            @click="$emit('close')"
          ></button>
        </div>
        <slot />
        <div v-if="isShowButton" class="pop_foot">
          <button type="button" @click="$emit('reset')">초기화</button>
          <button type="button" @click="$emit('submit')">선택완료</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addBodyOverflowHidden,
  removeBodyOverflowHidden,
} from '../../utils/CommonUtils';

export default {
  props: {
    title: {},
    isShowButton: { default: true },
    isNewBrandPop: { default: false },
  },
  created() {
    addBodyOverflowHidden();
  },
  destroyed() {
    removeBodyOverflowHidden();
  },
};
</script>

<style></style>
