<template>
  <down-full-up-popup
    @close="$emit('close')"
    :title="
      $t('Delivery.EditReviewTitle', {
        TitleState: isChange ? $t('Ect.Edit') : $t('Delivery.write'),
      })
    "
  >
    <template slot="body">
      <div class="wrap">
        <!--        <back-title :title="$t('Review.ReviewForm')" />-->

        <div class="layout_body box_p0 write_layout">
          <div v-if="product" class="review_con disflex">
            <product-image :item="product" class="review_pro" />
            <div class="detail_pro">
              <h6 class="fontEn font2">{{ product.cit_name }}</h6>
              <p class="font2">
                {{ product.cit_summary }}
              </p>
            </div>
          </div>
          <div class="inner p04">
            <ul class="wrap_flex write_warp m0">
              <li class="wrap_flex">
                <h4 class="write_tit">{{ $t('Review.AlertGrade') }}</h4>
                <review-score v-model="cre_score" :edit="true" />
              </li>
              <li>
                <label for="review_tit" class="write_tit">
                  {{ $t('Review.AlertTitle') }}
                </label>
                <input
                  id="review_tit"
                  v-model="cre_title"
                  type="text"
                  class="input_enter"
                  maxlength="50"
                  placeholder="제목을 입력해주세요."
                />
                <p>
                  <strong>{{ cre_title.length }}</strong>
                  /50
                </p>
              </li>
              <li>
                <label for="reviwe_con" class="basic_tit">
                  {{ $t('Review.AlertSatisfied') }}
                </label>
                <textarea
                  id="reviwe_con"
                  v-model="cre_content"
                  class="input_enter"
                  placeholder="내용을 입력해주세요."
                  maxlength="1000"
                ></textarea>
                <p>
                  <strong>{{ cre_content.length }}</strong>
                  /1,000
                </p>
              </li>
              <li class="pb14">
                <h6 class="write_tit">{{ $t('Review.AlertAttach') }}</h6>
                <ul class="pt_add_box p0">
                  <li
                    v-for="(url, idx) in urls"
                    :key="idx"
                    :class="url ? 'added_img' : ''"
                    :style="url ? `background-image: url(${url})` : ''"
                    @click="handleClickImage(url, idx)"
                  >
                    <label v-if="url === ''" for="files">
                      <input
                        id="files"
                        type="file"
                        accept="image/*"
                        multiple
                        @change="handleChangeFile"
                      />
                    </label>
                  </li>
                </ul>
                <p>{{ $t('Review.AlertFormat') }}</p>
              </li>
            </ul>
            <div class="presonal_selec" style="display: none">
              <h4 class="write_tit">{{ $t('Review.PersonalTerm') }}</h4>
              <div
                class="agreement_txt"
                v-html="$t('Review.PersonalTerm1')"
              ></div>
              <p>
                <input id="write" v-model="agree" type="checkbox" />
                <label for="write" class="chkbox font2">
                  {{ $t('Review.AgreePersonal') }}
                </label>
              </p>
            </div>
            <div class="presonal_fit">
              <h4 class="write_tit">{{ $t('Review.EnterInfo') }}</h4>
              <radio-group
                v-model="fit"
                :title="$t('Review.Fit')"
                name="fit"
                :items="[
                  { key: '슬림', label: $t('Review.Slim') },
                  { key: '레귤러', label: $t('Review.Regular') },
                  { key: '오버사이즈', label: $t('Review.OverSize') },
                ]"
              />
              <!--          <radio-group-->
              <!--            v-model="size"-->
              <!--            title="사이즈"-->
              <!--            name="size"-->
              <!--            :items="[-->
              <!--              { key: '작음', label: '작음' },-->
              <!--              { key: '적당함', label: '적당함' },-->
              <!--              { key: '큼', label: '큼' },-->
              <!--            ]"-->
              <!--          />-->

              <radio-group
                v-model="thickness"
                :title="$t('Review.Thickness')"
                name="thickness"
                :items="[
                  { key: '얇음', label: $t('Review.Thin') },
                  { key: '보통', label: $t('Review.Normal') },
                  { key: '도톰함', label: $t('Review.thick') },
                ]"
              />

              <radio-group
                v-model="elasticity"
                :title="$t('Review.Elasticity')"
                name="elasticity"
                :items="[
                  { key: '좋음', label: $t('Review.Good') },
                  { key: '약간있음', label: $t('Review.ALittle') },
                  { key: '없음', label: $t('Review.Nope') },
                ]"
              />
              <radio-group
                v-model="opacity"
                :title="$t('Review.SeeThrough')"
                name="opacity"
                :items="[
                  { key: '좋음', label: $t('Review.Good') },
                  { key: '약간있음', label: $t('Review.ALittle') },
                  { key: '없음', label: $t('Review.Nope') },
                ]"
              />
            </div>
          </div>
          <div class="write_caption">
            <h6 class="allPage_tit">{{ $t('Review.PleaseNote') }}</h6>
            <p class="inner" v-html="$t('Review.PleaseNote1')"></p>
          </div>
        </div>
        <button
          type="button"
          class="black_btn font4 btn_pop_open"
          @click="handleClickSubmit"
        >
          {{ $t('Review.Registration') }}
        </button>
      </div>
    </template>
  </down-full-up-popup>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import ReviewScore from '../../components/ui/ReviewScore/ReviewScore.vue';
import RadioGroup from '../../components/Inputs/RadioGroup/RadioGroup.vue';
import useToast from '../../composables/dialogs/useToast';
import useConfirm from '../../composables/dialogs/useConfirm';
import ProductAPI from '../../apis/ProductAPI';
import useUserInfo from '../../composables/user/useUserInfo';
import ProductImage from '../../components/ui/ProductImage/ProductImage.vue';
import DownFullUpPopup from '@Popups/DownFullUpPopup';

export default {
  components: {
    DownFullUpPopup,
    ReviewScore,
    RadioGroup,
    ProductImage,
  },
  // props: ['cit_id', 'item', 'updateItem'],
  props: {
    orderId: { type: String },
    codId: { type: String },
    cit_id: { type: String },
    reviewItem: Object,
    baseURL: {
      default: process.env.VUE_APP_FILE_URL,
    },
  },

  setup(props, context) {
    const { info } = useUserInfo();
    const { addMsg } = useToast();
    const { showConfirm } = useConfirm();

    const state = reactive({
      mem_id: info?.mem_id,
      cde_id: context.root.$route.params.cde_id,
      cre_title: props.reviewItem ? props.reviewItem.cre_title : '',
      cre_score: props.reviewItem ? props.reviewItem.cre_score : 5,
      cre_content: props.reviewItem ? props.reviewItem.cre_content : '',
      fit: props.reviewItem ? props.reviewItem.fit : '',
      size: props.reviewItem ? props.reviewItem.size : '',
      elasticity: props.reviewItem ? props.reviewItem.elasticity : '',
      thickness: props.reviewItem ? props.reviewItem.thickness : '',
      opacity: props.reviewItem ? props.reviewItem.opacity : '',
      agree: false,
      cod_id: localStorage.getItem('cod_id')
        ? localStorage.getItem('cod_id')
        : props.codId,
      order_id: localStorage.getItem('order_id')
        ? localStorage.getItem('order_id')
        : props.orderId,
      files: [],
      urls: new Array(5).fill(''),
      product: null,
      isChange: props.reviewItem !== undefined,
      cre_id: props.reviewItem ? props.reviewItem.cre_id : '',
      idx: 0,
      getValue: {
        img_file1: '',
        img_file2: '',
        img_file3: '',
        img_file4: '',
        img_file5: '',
      },
      reviewFiles: [],
    });
    console.log(state.isChange);
    console.log(props.reviewItem);

    const urlsImg = [];

    if (props.reviewItem) {
      // for (let i = 1; i < 6; i++) {
      //   const url = props.reviewItem[`img_file${i}`];
      //   console.log(url, 'url~~~~~~~~~');
      //   if (url) {
      //     urlsImg.push(url);
      //     console.log(urlsImg.length);
      //     state.urls[urlsImg.length - 1] =
      //       url.substr(0, 4) === 'http' ? url : props.baseURL + url;
      //   }
      // }
      state.urls[0] = props.reviewItem.img_file1
        ? process.env.VUE_APP_FILE_URL + props.reviewItem.img_file1
        : '';
      state.urls[1] = props.reviewItem.img_file2
        ? process.env.VUE_APP_FILE_URL + props.reviewItem.img_file2
        : '';
      state.urls[2] = props.reviewItem.img_file3
        ? process.env.VUE_APP_FILE_URL + props.reviewItem.img_file3
        : '';
      state.urls[3] = props.reviewItem.img_file4
        ? process.env.VUE_APP_FILE_URL + props.reviewItem.img_file4
        : '';
      state.urls[4] = props.reviewItem.img_file5
        ? process.env.VUE_APP_FILE_URL + props.reviewItem.img_file5
        : '';
      console.log(props.reviewItem, '?????????');
      state.reviewFiles = [
        props.reviewItem.img_file1,
        props.reviewItem.img_file2,
        props.reviewItem.img_file3,
        props.reviewItem.img_file4,
        props.reviewItem.img_file5,
      ];
      console.log(state.files, 'files');
    }
    console.log(state.files, 'files');
    const loadProduct = async () => {
      const { data } = await ProductAPI.getProduct(
        props.cit_id ? props.cit_id : context.root.$route.params.cit_id
      );

      state.product = data.result_data;
    };

    loadProduct();
    const validate = () => {
      const titleNoInput = state.cre_title.length === 0;
      const contentNoInput = state.cre_content.length === 0;

      if (titleNoInput) {
        addMsg(context.root.$t('Review.AlertReviewTitle'));
        return false;
      }

      if (contentNoInput) {
        addMsg(context.root.$t('Review.AlertContent'));
        return false;
      }

      // if (state.agree === false) {
      //   addMsg('개인 정보 선택 수집/이용에 동의해주세요.');
      //   return false;
      // }

      return true;
    };

    const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    const fileValidate = (files) => {
      let isImage = true;

      [...files].forEach((file) => {
        if (isImage) {
          isImage = imageTypes.indexOf(file['type'].toLowerCase()) > -1;
        }
      });

      if (isImage === false) {
        addMsg(context.root.$t('Review.AlertFormat'));
        return false;
      }

      if (state.files.length + files.length > 6) {
        addMsg(context.root.$t('Review.AlertMax'));
        return false;
      }

      return true;
    };

    const createReview = async () => {
      const params = { ...state };

      params['cit_id'] = props.cit_id
        ? props.cit_id
        : context.root.$route.params.cit_id;

      state.files.forEach((file, idx) => {
        params[`img_file${idx + 1}`] = file;
      });

      const { data } = await ProductAPI.createReview(params);

      if (data.status) {
        addMsg(context.root.$t('Review.AlertRegistration'));
        if (!props.cit_id) {
          context.root.$router.replace(
            `/product/review/${
              props.cit_id ? props.cit_id : context.root.$route.params.cit_id
            }`
          );
        } else if (props.cit_id) {
          context.emit('close');
        }
        localStorage.removeItem('order_id');
        localStorage.removeItem('cod_id');
        context.emit('update', true);
      }
    };
    const updateReview = async () => {
      const params = { ...state, ...state.getValue };
      console.log(params, 'params');
      console.log({ ...state.getValue }, '...state.getValue');

      params['cit_id'] = props.cit_id
        ? props.cit_id
        : context.root.$route.params.cit_id;

      const { data } = await ProductAPI.updateReviewDetail(params);

      if (data.status) {
        addMsg(context.root.$t('Review.AlertRegistration'));
        if (!props.cit_id) {
          context.root.$router.replace(
            `/product/review/${
              props.cit_id ? props.cit_id : context.root.$route.params.cit_id
            }`
          );
        } else if (props.cit_id) {
          context.emit('close');
        }
        localStorage.removeItem('order_id');
        localStorage.removeItem('cod_id');
        context.emit('update', true);
      }
    };
    // const deleteImage = async (index) => {
    //
    //   console.log(state.urls[index]);
    // };

    return {
      ...toRefs(state),
      urlsImg,
      handleChangeFile(e) {
        if (props.reviewItem) {
          const files = [...e.target.files];
          state.files = [...state.files, ...files];
          if (fileValidate(files)) {
            files.forEach(() => {
              state.urls[state.idx] = URL.createObjectURL(files[0]);
            });
            state.getValue[`img_file${state.idx + 1}`] = files[0];
            console.log({ ...state.getValue }, '...state.getValue');
            state.urls[state.idx] = URL.createObjectURL(files[0]);
            document
              .querySelectorAll('.pt_add_box li')
              [state.idx].classList.add('added_img');
            document.querySelectorAll('.pt_add_box li')[
              state.idx
            ].style.backgroundImage =
              'url(' + URL.createObjectURL(files[0]) + ')';
          }
        } else {
          const files = [...e.target.files];

          if (fileValidate(files)) {
            const urls = state.urls.filter((url) => url !== '');

            files.forEach((file) => {
              urls.push(URL.createObjectURL(file));
            });

            for (let i = urls.length; i < 5; i++) {
              urls.push('');
            }

            state.urls = urls;
            state.files = [...state.files, ...e.target.files];
            console.log('12323');
            console.log(state.urls);
            console.log(state.files);
            console.log('12323');
          } else {
            e.target.value = '';
          }
        }
      },

      handleClickSubmit() {
        if (validate()) {
          showConfirm(
            context.root.$t('Review.AlertReviewConfirm'),
            createReview
          );
        }
        //수정
        if (state.isChange) {
          showConfirm(
            context.root.$t('CustomerService.AlertEdit'),
            updateReview
          );
          console.log({ ...state });
        }
      },
      async handleClickImage(url, index) {
        state.idx = index;

        if (props.reviewItem) {
          if (props.reviewItem[`img_file${index + 1}`]) {
            console.log(url, index);
            console.log(props.reviewItem[`img_file${index + 1}`]);

            const { data } = await ProductAPI.deleteReviewFile({
              cre_id: state.cre_id,
              mem_id: state.mem_id,
              img_key: `img_file${index + 1}`,
            });

            if (data.result) {
              addMsg(context.root.$t('Button.AlertDeleteComplete'));
            }
          }
          const imageList = document.querySelectorAll('.pt_add_box li');
          // 수정일때
          imageList[state.idx].classList.remove('added_img');
          imageList[state.idx].style.backgroundImage = '';

          state.reviewFiles[state.idx] = '';

          state.files = [...state.files];

          if (state.idx > -1) {
            const urls = state.urls;
            urls[state.idx] = '';
            urls.push('');

            const files = state.files;

            state.urls = urls;
            state.files = files;
            state.urls.pop();
            console.log(state.files);
            console.log(state.urls);
          }
        } else {
          if (url === '') return;

          const idx = state.urls.findIndex((value) => value === url);

          if (idx > -1) {
            const urls = [...state.urls];
            urls.splice(idx, 1);
            urls.push('');
            console.log(idx);

            const files = [...state.files];
            files.splice(idx, 1);

            state.urls = urls;
            console.log(state.urls, 'state.urls');
            state.files = files;
            console.log(state.files, 'state.files');
          }
        }
      },
    };
  },
};
</script>

<style></style>
