<template>
  <ul class="select_list_box">
    <slot />
  </ul>
</template>

<script>
export default {};
</script>

<style></style>
