<template>
  <header class="layout_header">
    <div class="hinner disflex">
      <close-button />
      <h2 class="new_title brand_name type2">
        {{ title }}
        <slot />
      </h2>
      <basket-button />
    </div>
  </header>
</template>

<script>
import CloseButton from './components/CloseButton/CloseButton.vue';
import BasketButton from './components/BasketButton/BasketButton.vue';
export default {
  components: { BasketButton, CloseButton },
  props: ['title'],
};
</script>

<style></style>
