<template>
  <div class="layout_popup full_layout_pop">
    <div class="dim"></div>
    <div class="layout_contain">
      <div class="layout_box">
        <div class="pop_header disflex header_2btn">
          <button
            type="button"
            class="back btn_dim_close"
            @click="$emit('close')"
          ></button>
          <h2 class="new_title brand_name">{{ $t('Review.PhotoDetail') }}</h2>
        </div>
        <div class="pop_body black_bg">
          <article class="lookbook_banner">
            <div class="nav">{{ navText }}</div>
            <div class="sinner">
              <swiper class="prod_swiper pop_swiper" :options="options">
                <swiper-slide
                  v-for="(url, idx) in urls"
                  :key="idx"
                  class="prod_box"
                >
                  <image-bg-src :src="url" class="prod_img" />
                </swiper-slide>
                <div slot="scrollbar" class="swiper-scrollbar"></div>
              </swiper>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ImageBgSrc from '../ImageBlock/ImageBgSrc.vue';
import { ref } from 'vue-demi';

export default {
  components: {
    Swiper,
    SwiperSlide,
    ImageBgSrc,
  },
  props: ['urls', 'activeIndex'],
  setup(props) {
    const navText = ref(`${props.activeIndex + 1}/${props.urls.length}`);

    return {
      navText,
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
        initialSlide: props.activeIndex,
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        on: {
          transitionEnd() {
            navText.value = `${this.activeIndex + 1}/${this.slides.length}`;
          },
        },
      },
    };
  },
};
</script>

<style>
.nav {
  width: 50px;
  position: fixed !important;
  top: 76px;
  color: #fff;
  right: 14px;
  font-size: 12px;
  color: #fff;
  vertical-align: -4px;
  opacity: 0.6;
}

.full_layout_pop .layout_contain {
  animation: fadeInUp 0.5s;
}
.prod_img {
  padding-top: 130%;
}
</style>
