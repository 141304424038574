<template>
  <div class="layout_popup full_layout_pop open" data-popup="personal_info_pop">
    <div class="layout_contain">
      <div class="layout_box order_coupon">
        <div class="pop_header disflex">
          <slot name="header">
            <button
              type="button"
              class="leftclose btn_dim_close"
              @click="$emit('close')"
            />
            <h2 class="new_title">{{ title }}</h2>
            <slot name="rightBottom" />
          </slot>
        </div>
        <div class="pop_body">
          <div :class="`${className} inner`">
            <slot body="body" name="body"></slot>
          </div>
        </div>
        <div class="pop_foot pos_fixed0">
          <slot body="footer" name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addBodyOverflowHidden,
  removeBodyOverflowHidden,
} from '../../utils/CommonUtils';

export default {
  props: ['title', 'className'],
  created() {
    addBodyOverflowHidden();
  },
  destroyed() {
    removeBodyOverflowHidden();
  },
};
</script>

<style>
.layout_popup.full_layout_pop .btn_dim_close {
  width: 24px;
  height: 24px;
}
</style>
